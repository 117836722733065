import React, { useState } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Home() {
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [count, setcount] = useState(0);
  const [countTwo, setcountTwo] = useState(0);
  const [Name, setName] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [property, setproperty] = useState("");

  // console.log(count , countTwo , dateStart , dateEnd , Name , phone , email , property , "yyhrjgiyul");

  function onChangeHandler(value) {
    const datsSeparete = value[0].toString().slice(0, 15);
    setDateStart(value[0]);
    setDateEnd(value[1]);
  }
  const increment = () => {
    setcount(count + 1);
    // if(count >= 3){
    //   setcount(3)
    //   alert("Only 3 Members allowed")
    // }
  };

  const decrement = () => {
    setcount(count - 1);
    if (count === 0) {
      setcount(0);
    }
  };

  const incrementTwo = () => {
    setcountTwo(countTwo + 1);
    // if(countTwo >= 3){
    //   alert("Only 3 Members allowed")
    // setcountTwo(3)

    // }
  };

  const decrementTwo = () => {
    setcountTwo(countTwo - 1);
    if (countTwo === 0) {
      setcountTwo(0);
      // alert("Only 3 Members allowed")
    }
  };

  const conttactinfo = async () => {
    // alert("hi")
    try {
      if (
        Name &&
        email &&
        count &&
        countTwo &&
        dateStart &&
        dateEnd &&
        property &&
        phone
      ) {
        const result = await axios.post(
          "https://hiddenvalley.onrender.com/api/formdata",
          {
            adult: count,
            child: countTwo,
            name: Name,
            phone: phone,
            email: email,
            datesfirst: dateStart,
            datessecond: dateEnd,
            property: property,
          }
        );
        toast(
          `Hello ${Name} your inquiry has been received successfully. Our team will contact you soon!!!`,
          {
            position: "bottom-right",
          }
        );
      } else {
        toast(`Please fill all fields!`, {
          position: "bottom-right",
        });
      }
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <>
 

      <ToastContainer />
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-body">
           <div className="uytgdfuygk">
           <h1>Enquire for Booking</h1>

           <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
              >
               <img src="/images/closemodel.svg" />
              </button>
           </div>
           {/* https://postimg.cc/yJCsRVck */}
                
                <p className="lorendataasjhb">
                Feel free to fill in your details and we shall connect with you.
                </p>

                <div className="side" id="numobj">
                  NUMBER OF TRAVELLERS
                </div>

                <div className="childadult">
                  <div className="inneradult">
                    <div className="innercount">
                      <div className="count1" onClick={decrement}>
                        -
                      </div>
                      <div className="countinhvjb">{count}</div>
                      <div className="count" onClick={increment}>
                        +
                      </div>
                    </div>
                    <div className="hinhhh">uk</div>
                    <p className="adultchildata">Adults</p>
                  </div>
                  <div className="inneradult">
                    <div className="innercount">
                      <div className="count1" onClick={decrementTwo}>
                        -
                      </div>
                      <div className="countinhvjb">{countTwo}</div>
                      <div className="count" onClick={incrementTwo}>
                        +
                      </div>
                    </div>
                    <div className="hinhhh">uk</div>

                    <p className="adultchildata">Children 0-11 yrs</p>
                  </div>
                </div>

                <div className="side" id="detanjbnbv">
                  Details
                </div>

                <div className="inputdatas">
                  <input
                    type="text"
                    placeholder="FULL NAME*"
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <input
                    type="text"
                    className="hyhfuygi"
                    value={phone}
                    onChange={(e) => setphone(e.target.value)}
                    placeholder="PHONE*"
                  />
                </div>
                <input
                  className="inputdataslast"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  placeholder="EMAIL*"
                  type="text"
                />

                <div className="side" id="detanjbnbv">
                  SELECT DATE & PROPERTY
                </div>
                <div className="datapicker">
                  <DatePicker
                    id="dateStartEnd"
                    selectsRange={true}
                    startDate={dateStart}
                    endDate={dateEnd}
                    onChange={onChangeHandler}
                    placeholderText="CHECK-IN & CHECK-OUT"
                    dateFormat="MMM dd yyyy "
                    minDate={new Date()}
                    className={"WAREFGTRWE"}
                    showDisabledMonthNavigation
                  />
                </div>
                <div className="datapicker" id="hiojji">
                  <select
                    value={property}
                    onChange={(e) => setproperty(e.target.value)}
                  >
                    <option>SELECT PROPERTY</option>
                    <option>Sunset Cottage</option>
                    <option>Sunset Villa</option>
                    <option>Hidden Valley Riverside</option>
                    <option>Nethra Hidden Valley</option>
                    <option>Tinyhouse Hidden Valley</option>
                  </select>
                </div>

                <div className="equiry" onClick={conttactinfo}>
                  Enquire Now
                </div>
              </div>
          
            </div>
          </div>
        </div>
      </div>
      <div className="home">
        <div className="homeinner">
          <div className="innerimagee">
            <img src="/images/homelogo.webp" className="wensite" />
            <img src="/images/homelogomob.webp" className="mobile" />
          </div>

          <div className="lorentext">
            <p className="lorefirst">ABOUT US</p>
            <h1>Reconnect with Nature, Relax in Comfort</h1>
            <p className="paratext">
              {" "}
              Welcome to Hiddenvalley Stays, a collection of homestays and
              resorts in the heart of Coorg. Each stay offers a unique blend of
              comfort, Coorgi hospitality, and nature-inspired activities like
              treks and plantation tours. Whether it's the cozy Sunset Cottage
              or the adventurous Treehouse, we provide a perfect escape to relax
              and reconnect with nature.
            </p>
            <div id="jhyjykuil" className="vuyghioi">
              <div data-bs-toggle="modal" data-bs-target="#exampleModal" className="bookenow">ENQUIRE NOW</div>
            </div>
          </div>

          <div>
            <h1 className="stays" id="stayinne">
              Our Stays
            </h1>
            <div className="displaycontemt">
              <div id="conntemtom">
                <div
                  id="carouselExampleControls"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        className="d-block w-100"
                        src="/images/image1.webp"
                        alt="First slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/images/image11.webp"
                        alt="Second slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/images/image111.webp"
                        alt="Third slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/images/image1111.webp"
                        alt="Third slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/images/image11111.webp"
                        alt="Third slide"
                      />
                    </div>
             
                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
                </div>
                <h1>Sunset Cottage</h1>
                <div className="dividedcontent">
                  <div className="tydfg">
                    <img src="/images/one.svg" /><span className="kjgvyugi" >3 Mountain View Chalets </span><span>: 6 rooms total (2 per chalet) – accommodates 12 people.</span>
                  </div>
             
                </div>
                <div className="dividedcontent">
                <div className="tydfg">
                    <img src="/images/two.svg" /><span className="kjgvyugi" >Suite Chalet </span><span>: 2 rooms – accommodates 4 people.</span>
                  </div>
                </div>
                <p className="jgiuygh">
                  <span className="htdgcyuv">Amenities : </span>
                  Authentic Coorgi Cuisine, Campfire, Volleyball Court,
                  Badminton Court, Machan for Bird Watching, Wifi, River Trek,
                  Guided Plantation Tour, Outdoor Barbecue, Trekking Guides,
                  Local Sight-seeing Assistance
                </p>
              <div className="jhgdfutyuk">
              <a href="https://api.whatsapp.com/send?phone=919611588968&text=Can%20I%20check%20availability%20for%20my%20dates%3F"><div  className="uytdfug7yiu"><img className="hfcutyukgjk" src="/images/wapp.svg" />WhatsApp</div></a>
              <a href="tel:9611588968"><div  className="uytdfug7yiu2">Call Us</div></a>
              </div>
              </div>
              <div id="conntemtom">
                <div
                  id="carouselExampleControls1"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        className="d-block w-100"
                        src="/images/image2.webp"
                        alt="First slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/images/image22222.webp"
                        alt="Third slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/images/image22.webp"
                        alt="Second slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/images/image222.webp"
                        alt="Third slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/images/image2222.webp"
                        alt="Third slide"
                      />
                    </div>
            
                    </div>
                
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls1" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls1" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
                </div>
                <h1>Sunset Villa</h1>
                <div className="dividedcontent">
                <div className="tydfg">
                    <img src="/images/three.svg" /><span className="kjgvyugi" >Ground Floor </span><span>: 4 rooms – accommodates 8 people</span>
                  </div>
                </div>
                <div className="dividedcontent">
                <div className="tydfg">
                    <img src="/images/three.svg" /><span className="kjgvyugi" >First Floor </span><span>: 4 rooms – accommodates 8 people.</span>
                  </div>
                </div>
                <p className="jgiuygh">
                <span className="htdgcyuv">Amenities : </span>
                  Authentic Coorgi Cuisine, Campfire, Volleyball Court,
                  Badminton Court, Machan for Bird Watching, Wifi, River Trek,
                  Guided Plantation Tour, Outdoor Barbecue, Trekking Guides,
                  Local Sight-seeing Assistance
                </p>
                <div className="jhgdfutyuk">
                <a href="https://api.whatsapp.com/send?phone=919611588968&text=Can%20I%20check%20availability%20for%20my%20dates%3F"><div className="uytdfug7yiu"><img className="hfcutyukgjk" src="/images/wapp.svg" />WhatsApp</div></a>
                <a href="tel:9611588968"><div  className="uytdfug7yiu2">Call Us</div></a>
                </div>
              </div>
              <div id="conntemtom">
                <div
                  id="carouselExampleControls2"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        className="d-block w-100"
                        src="/images/image3.webp"
                        alt="First slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/images/image33.webp"
                        alt="Second slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/images/image333.webp"
                        alt="Third slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/images/image3333.webp"
                        alt="Third slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/images/image33333.webp"
                        alt="Third slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/images/image333333.webp"
                        alt="Third slide"
                      />
                    </div>
                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
                </div>
                <h1>Hidden Valley Riverside</h1>
                <div className="dividedcontent">
                <div className="tydfg">
                    <img src="/images/four.svg" /><span className="kjgvyugi" >Farmhouse </span><span className="jhfuyvug">: 3 family rooms (4 people each) + 1 dorm room (6 people) – accommodates 18 people total.</span>
                  </div>
                </div>
                <div className="dividedcontent">
                <div className="tydfg">
                    <img src="/images/five.svg" /><span className="kjgvyugi" >Treehouse </span><span>: 2 rooms – accommodates 6 people.</span>
                  </div>
                </div>
                <p className="jgiuygh">
                <span className="htdgcyuv">Amenities : </span>
                  Authentic Homemade Coorgi Cuisine, Bonfire, Guided Trek to
                  Private Waterfall, Guided Coffee Plantation Tour, Wifi, Indoor
                  and Outdoor Games, Jeep Safari, Local Sight-seeing Assistance
                </p>
                <div className="jhgdfutyuk">
                <a href="https://api.whatsapp.com/send?phone=919611588968&text=Can%20I%20check%20availability%20for%20my%20dates%3F"><div  className="uytdfug7yiu"><img className="hfcutyukgjk" src="/images/wapp.svg" />WhatsApp</div></a>
                <a href="tel:9611588968"><div  className="uytdfug7yiu2">Call Us</div></a>
                </div>
              </div>
              <div id="conntemtom">
                <div
                  id="carouselExampleControls3"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        className="d-block w-100"
                        src="/images/image4.webp"
                        alt="First slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/images/image4n.webp"
                        alt="Second slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/images/image44.webp"
                        alt="Second slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/images/image444.webp"
                        alt="Third slide"
                      />
                    </div>
              
                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls3" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls3" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
                </div>
                <h1>Nethra Hidden Valley</h1>
                <div className="dividedcontent">
                <div className="tydfg">
                    <img src="/images/seven.svg" /><span className="kjgvyugi" >Glass House </span><span>: 4 rooms total – accommodates 8 people.</span>
                  </div>
                </div>
                <div className="dividedcontent">
                <div className="tydfg">
                    <img src="/images/six.svg" /><span className="kjgvyugi" >Cottage </span><span>: 2 rooms – accommodates 4 people.</span>
                  </div>
                </div>
             
                <p className="jgiuygh">
                <span className="htdgcyuv">Amenities : </span>
                  Bonfire, Homefood, Badminton Court, Private Waterfall Trek,
                  Guided Coffee Plantation Walk, Barbecue Equipment, Local
                  Sight-seeing Assistance
                </p>
                <div className="jhgdfutyuk" id="trdfuyiui">
                <a href="https://api.whatsapp.com/send?phone=919611588968&text=Can%20I%20check%20availability%20for%20my%20dates%3F"><div  className="uytdfug7yiu"><img className="hfcutyukgjk" src="/images/wapp.svg" />WhatsApp</div></a>
                <a href="tel:9611588968"><div  className="uytdfug7yiu2">Call Us</div></a>
                </div>
              </div>
              <div id="conntemtom">
                <div
                  id="carouselExampleControls4"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        className="d-block w-100"
                        src="/images/image5.webp"
                        alt="First slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/images/image55.webp"
                        alt="Second slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/images/image555.webp"
                        alt="Third slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/images/image5555.webp"
                        alt="Third slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src="/images/image5.webp"
                        alt="Third slide"
                      />
                    </div>
                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls4" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls4" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
                </div>
                <h1>Tinyhouse Hidden Valley</h1>
                <div className="dividedcontent">
                <div className="tydfg">
                    <img src="/images/eight.svg" /><span className="kjgvyugi" >2-Bedroom Cottage </span><span>: Accommodates 4 people.</span>
                  </div>
                </div>
             
                <p className="jgiuygh">
                <span className="htdgcyuv">Amenities : </span>
                  Home-cooked Meals, WiFi, Bonfire, Guided Plantation Tour,
                  Barbecue Equipment
                </p>
                <div className="jhgdfutyuk">
                <a href="https://api.whatsapp.com/send?phone=919611588968&text=Can%20I%20check%20availability%20for%20my%20dates%3F"><div  className="uytdfug7yiu"><img className="hfcutyukgjk" src="/images/wapp.svg" />WhatsApp</div></a>
                <a href="tel:9611588968"><div  className="uytdfug7yiu2">Call Us</div></a>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
