import React from "react";

function Concelattion() {
  return (
    <>
      <div className="jhyhfctyiu">
        <h1>Refund Policy for Hiddenvalley Stays</h1>
        <p>
          At Hiddenvalley Stays, we strive to provide a seamless experience for
          all our guests. However, we understand that plans can change, and
          thus, we offer a flexible refund policy. Please note that the refund
          policy is subject to variation during peak seasons, long weekends, and
          festive dates, based on demand and availability. For specific terms
          during these periods, guests are advised to inquire at the time of
          booking.
        </p>

        <h2>1. Dynamic Refund Policy</h2>
        <p>
          Peak Season and Festive Dates: Refunds and cancellations during peak
          seasons, festivals, and long weekends are subject to specific terms
          and conditions that may differ from the standard policy. Please verify
          the refund policy applicable to your booking at the time of
          reservation.
        </p>
        <p>
          Refund policies during off-peak periods will follow the guidelines
          outlined below.
        </p>

        <h2>2. Cancellation Policy</h2>
        <p> Cancellations made 30 days or more before check-in:</p>
        <p className="yhfgy7uyu">
          Guests are eligible for a full refund, minus a processing fee of 5% to
          cover administrative costs.
        </p>
        <p> Cancellations made between 15 and 30 days before check-in:</p>
        <p className="yhfgy7uyu">A 50% refund will be issued for cancellations in this period.</p>
        <p>Cancellations made within 15 days of check-in:</p>
        <p className="yhfgy7uyu">No refund will be provided for cancellations made in this window.</p>

        <h2>3. Date Change Policy</h2>
        <p>
          Date Change Requests: Guests who wish to modify their booking dates
          can submit a request, which is subject to room availability.
        </p>
        <p>
          Conditions: Date changes must be made well in advance of the check-in
          date and are not guaranteed. Should date changes be accepted, any
          difference in the room rate will apply, and additional charges may be
          incurred depending on the season and property availability.
        </p>

        <h2>4. Refund Processing</h2>
        <p>
          Refunds will be processed within 7-10 working days of cancellation
          confirmation.
        </p>
        <p>
          Any bank charges, transfer fees, or processing fees will be deducted
          from the total refundable amount.
        </p>

        <h2>5. Non-Refundable Exceptions</h2>
        <p>
          Certain promotional offers or bookings may have a non-refundable
          clause. Guests are advised to check the terms at the time of booking.
        </p>

        <h1>Terms and Conditions for Hiddenvalley Stays</h1>
        <p>
          At Hiddenvalley Stays, we prioritize guest comfort and transparency.
          Our terms and conditions ensure a smooth and enjoyable stay for all
          guests while protecting our properties. Please review the following
          policies carefully before booking your stay.
        </p>
        <h2>1. Booking Confirmation</h2>
        <p>
          Reservation Process: All reservations must be confirmed with a full
          payment or a minimum deposit as communicated during the booking
          process.
        </p>
        <p>
          Booking Acknowledgment: Once the booking is confirmed, guests will
          receive an email or message containing their booking details. Please
          ensure that the provided details are accurate.
        </p>
        <h2>2. Check-in and Check-out Policy</h2>
        <p>Check-in Time: 1:00 PM </p>
        <p>Check-out Time: 11:00 AM</p>
        <p>
          Early check-in or late check-out requests are subject to availability
          and may incur additional charges. Guests are requested to inform the
          property in advance if they wish to alter their check-in/check-out
          times.
        </p>

        <h2>3. Room Rates and Seasonal Pricing</h2>
        <p>
          Standard Rates: Room rates are subject to change based on demand,
          season, and special offers. The rates provided during booking are
          guaranteed only for that booking period.
        </p>
        <p>
          Seasonal Adjustments: During festivals, long weekends, or holidays,
          rates may be higher. Special discounts or promotional rates may not be
          applicable during these periods.
        </p>
        <p>
          Group Rates: Special rates may be available for group bookings. Please
          inquire for details.
        </p>

        <h2>4. Occupancy and Additional Guests</h2>
        <p>
          Each room or unit has a designated maximum occupancy, which will be
          communicated during booking.
        </p>
        <p>
          Additional Guests: Any guests beyond the specified room capacity may
          be subject to extra charges. Please inform the property in advance if
          additional guests will be staying.
        </p>
        <h2>5. Children’s Policy</h2>
        <p>
          Children under the age of 5 may stay free of charge when sharing a
          room with parents (without the need for an extra bed).
        </p>
        <p>
          Children aged 5 and above will be charged as per the property’s policy
          for extra beds or occupancy.
        </p>
        <h2>6. Pets</h2>
        <p>
          Hiddenvalley Stays generally has a no-pets policy, unless specified
          for particular properties. Guests are encouraged to inquire about the
          pet policy during booking if they plan to travel with pets.
        </p>
        <h2>7. Smoking Policy</h2>
        <p>
          Smoking is strictly prohibited in all indoor areas, including rooms
          and common areas. Designated smoking areas may be available on the
          property.
        </p>
        <p>
          A cleaning fee may be charged to guests found smoking inside rooms.
        </p>
        <h2>8. Damage to Property</h2>
        <p>
          Guests are expected to treat the property and its amenities with care.
        </p>
        <p>
          Damage or Loss: Any damage caused by the guest to property,
          furnishings, or equipment will result in an additional charge for
          repairs or replacement.
        </p>
        <h2>9. Facilities and Amenities Usage</h2>
        <p>
          Guests are free to enjoy the amenities and facilities offered at the
          property, as outlined in the booking details. Certain amenities, such
          as guided tours or outdoor activities, may incur additional charges.
        </p>
        <p>
          Guests are requested to follow safety guidelines and instructions when
          using the facilities.
        </p>
        <h2>10. Force Majeure</h2>
        <p>
          Hiddenvalley Stays is not responsible for cancellations,
          modifications, or loss of services due to events beyond our control,
          including but not limited to natural disasters, government actions,
          strikes, or pandemics.
        </p>
        <p>
          In such cases, bookings may be rescheduled at the discretion of the
          property, subject to availability.
        </p>
        <h2>11. Privacy Policy</h2>
        <p>
          Guest privacy is of utmost importance. Hiddenvalley Stays collects
          guest information solely to manage bookings and provide personalised
          service.
        </p>
        <p>
          No personal information will be shared with third parties unless
          required by law.
        </p>
        <h2>12. Changes to Terms and Conditions</h2>
        <p>
          Hiddenvalley Stays reserves the right to amend these terms and
          conditions at any time. Guests will be informed of any changes that
          may impact their booking.
        </p>
        <h2>13. Liability</h2>
        <p>
          Hiddenvalley Stays is not liable for any loss, damage, or injury
          sustained by guests during their stay. Guests are advised to take
          necessary precautions and ensure the safety of personal belongings.
        </p>
        {/* <h2></h2> */}
        <p className="uyftyui">These detailed terms and conditions clarify and set expectations for guests during their stay at Hiddenvalley Stays.</p>
      </div>
    </>
  );
}

export default Concelattion;
