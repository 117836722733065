import React, { useState } from "react";
import Sidebar from "./Sidebar/Sidebar";


function Navbar() {
  const [isopen, setisopen] = useState(false);
  const toggle = () => {
    setisopen(!isopen);
  };
  return <>
      {/* <Sidebar isopen={isopen} toggle={toggle} /> */}

  <div className="Navbar">
      <div id="Navbar">
        <div className="menu" onClick={toggle}>
          {/* <img src="/images/menu.svg" /> */}
        </div>
            <div className="routesnav" id="containers">
              {/* <a href="/">OUR STORY</a> */}
              <a href="#stayinne">OUR STAYS</a>
              <a href="/">CONTACT US</a>
            </div>
            <div id="containers" className="middlelogo">
            <a href="/">  <img src="/images/logo.svg" /></a>
            </div>
            <div id="containers" className="booknoehead">
              <div data-bs-toggle="modal" data-bs-target="#exampleModal" className="bookenow">ENQUIRE NOW</div>
            </div>
            <div className="menu">
          
        </div>
      </div>
  </div>
  </>
  ;
}

export default Navbar;
