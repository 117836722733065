import React from "react";

function Footer() {
  return (
    <>
      <div className="Footer">
        <div id="Footer">
          <div className="footerfirst">
            <div className="leftfootrside">
              <div className="uyguiykj">
                <img src="/images/logo.svg" />
              </div>

              <div className="footercontet fyutigy" id="left">
                <a id="hgfyugvh" href="/">
                  ABOUT US
                </a>
                <a id="footerspace" className="footerspace" href="/">
                  OUR STAYS
                </a>
              </div>
            </div>
            <div className="rightfooterend">
              <div className="footercontet" id="htdryui">
                <a className="ytdtf7giu" href="/">CONTACT US</a>
                <a id="footerspace" href="tel:9611588968">
                  CALL US AT : 9611588968 / 6362757985
                </a>
                <a id="mail" href="mailto:HIDDENVALLEYSTAY@GMAIL.COM">
                  EMAIL : hiddenvalleystays@gmail.com
                </a>
              </div>
              <div id="left">
                <div className="social">FOLLOW US :</div>
                <div className="socialmedia" id="footerspace">
                  {/* <img src="/images/li.svg" /> */}
                  {/* <img src="/images/tw.svg" /> */}
                  <a
                    href="https://www.instagram.com/hiddenvalleystays?igsh=MXRhazEzNGcyaDJoaQ=="
                  >
                    <img src="/images/ins.svg" />
                  </a>
                  <a href="https://www.facebook.com/offbeatgetaways?mibextid=LQQJ4d">
                    <img src="/images/face.svg" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerlast">
          <a href="/termsandrefund">
            {" "}
            <div>TERMS & CONDITION</div>
          </a>
          <a href="/termsandrefund">
            {" "}
            <div>REFUND POLICY</div>
          </a>
        </div>
      </div>
      


    </>
  );
}

export default Footer;
