import React from 'react'
import {BrowserRouter , Routes , Route} from "react-router-dom"
import "./App.css"
import Home from './Components/Layout/Home'
import Contact from './Components/Layout/Contact'
import Navbar from './Components/Layout/Navbar'
import Footer from './Components/Layout/Footer'
import Concelattion from './Components/Layout/Concelattion'
function App() {
  return (
   <>
   <BrowserRouter>
   <Navbar />
   <Routes>
    <Route path='/' element={<Home />}></Route>
    <Route path='/contact' element={<Contact />}></Route>
    <Route path='/termsandrefund' element={<Concelattion />} ></Route>
   </Routes>
   <Footer />
   </BrowserRouter>
   </>
    
  )
}

export default App
