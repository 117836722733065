import React from 'react'

function Contact() {
  return (
   <>
   </>
  )
}

export default Contact
